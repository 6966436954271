import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Box } from "@material-ui/core";

import { screen } from "@displaygecko/dg-modules";
import { Region } from "@displaygecko/dg-components";

const mapState = (state, ownProps) => ({
  screen: screen.screenSelector(ownProps.screenId)(state)
});

const mapDispatch = {
  getScreen: screen.getScreen
};

function Screen({ screenId, screen, getScreen }) {
  useEffect(() => {
    getScreen(screenId);
  }, [getScreen, screenId]);

  console.log(`[Screen ID ${screenId}] Render`);
  return (
    <div style={{ height: "100vh" }}>
      {screen ? (
        screen.regions.map((region, index) => (
          <Region key={index} region={region} />
        ))
      ) : (
        <Box />
      )}
    </div>
  );
}

export default connect(mapState, mapDispatch)(Screen);
